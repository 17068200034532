@import 'scss/mixins.scss';

// Import fonts
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-100/MuseoSansCyrl-100', 100, normal);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-100Italic/MuseoSansCyrl-100Italic', 100, italic);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-300/MuseoSansCyrl-300', 300, normal);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-300Italic/MuseoSansCyrl-300Italic', 300, italic);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-500/MuseoSansCyrl-500', 500, normal);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-500Italic/MuseoSansCyrl-500Italic', 500, italic);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-700/MuseoSansCyrl-700', 700, normal);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-700Italic/MuseoSansCyrl-700Italic', 700, italic);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-900/MuseoSansCyrl-900', 900, normal);
@include font_face('MuseoSansCyrl', '../fonts/MuseoSansCyrl-900Italic/MuseoSansCyrl-900Italic', 900, italic);

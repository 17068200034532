/* Slider */
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.visibleOverflow {
        .slick-list {
            overflow: unset;
        }
    }

    .MuiBox-root,
    .MuiPaper-root {
        outline: none;
    }
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    align-items: stretch;
    // display: block;
    // margin-left: auto;
    // margin-right: auto;

    &:before,
    &:after {
        content: '';
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    // height: 100%;
    min-height: 1px;
    & > div {
        height: 100%;
    }
    [dir='rtl'] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

// Arrows
.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 46px / 2));
}

.slick-prev {
    left: -20px;
}

.slick-next {
    right: -20px;
}

// Dots
.slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 32px 0 0 0;

    li {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }

    .slick-active button {
        background-color: #fe8157;
    }

    button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.12);
        border: none;
        font-size: 0;
        cursor: pointer;
        transition: all 0.4s ease;
        &:focus {
            outline: none;
        }
        &:hover {
            background-color: #fe8157;
        }
    }
}

@import './scss/fonts.scss';

// Base styles
::selection {
    background-color: #fe8157;
    color: #fff;
}

body {
    min-width: 375px;
    background-color: #fff !important;
}

#root {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.router-link {
    text-decoration: none;
}
.rodal-dialog{
    padding: 0;
    border-radius: 9px;
    overflow: hidden;
}